import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import ConfirmAddressBaseComponent, {
  ConfirmAddressData,
} from 'components/FormPage/confirmAddress';
import { DriverType } from 'types/forms';

type ConfirmAddressProps = {
  data: {
    csAddDriverConfirmAddress: ConfirmAddressData;
  };
  location: Location;
};

const ConfirmAddressPage: React.FC<ConfirmAddressProps> = ({ data, location }) => (
  <ConfirmAddressBaseComponent
    data={data.csAddDriverConfirmAddress}
    location={location}
    driverType={DriverType.PERMANENT}
  />
);

export default ConfirmAddressPage;

export const query = graphql`
  query {
    csAddDriverConfirmAddress {
      title
      heading
      rich_text
    }
  }
`;
